<template>
    <v-hover v-slot="{ hover }">
        <v-sheet
            style="transition: all .2s; border: 1px solid #ccc"
            :class="hover ? 'elevation-15 u-cursor-pointer' : ''"
            rounded="lg"
            @click="$router.push(`/print/${card.id}`)"
        >
            <v-img :src="$store.state.imagePath + card.image_path" />
            <div class="py-3">
                <h6 class="primary--text text-center text-body-1 font-weight-bold">
                    {{ card.title }}
                </h6>
            </div>
        </v-sheet>
    </v-hover>
</template>

<script>
export default {
    props: {
        card: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>